@font-face {
    font-family: 'FROH';
    src: url('./fonts/FROH.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'OpenSans';
    src: url('./fonts/OpenSans.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh; /* Устанавливаем высоту на 100% для корневых элементов */
    overflow: hidden; /* Отключаем скроллинг страницы */
}



#root {
    width: 100%;
    height: 100vh; /* Высота #root на 100% высоты экрана */
    overflow-y: auto; /* Включаем вертикальный скроллинг для содержимого */
    overscroll-behavior: none;
}

#root.noscroll {
    overflow: hidden !important;
    /*height: 100vh;*/
    /*width: 100vw;*/
    /*position: fixed;*/
    /*top: 0;*/
    /*left: 0;*/
}


@media only screen and (max-height: 700px) {
    /*#root {*/
    /*    transform: scale(0.8);*/
    /*    margin: 0 auto;*/
    /*}*/
    .scanner-card, .video-content, .sheet-content {
        scale: 0.8;
        margin: 0 auto;
    }

    .carousel {
        scale: 0.8;
    }

    .carousel-buttons {
        scale: 0.8;
    }

    /*.game-container {*/
    /*    scale: 0.8;*/
    /*    top: -30px;*/
    /*}*/
}

.content {
    margin-top: 80px;
    width: 100%;
    min-height: 80vh;
}


.background {
    position: relative;
    background-color: #00000090;
    background-image: url('./assets/bg.png');
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


.container {
    min-height: 100vh;
    width: 100%;
    max-width: 440px;
    /* display: flex
; */
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(100px);
    /* overflow: hidden; */
    /* overflow-y: auto; */
    /* overscroll-behavior: contain;

}

.text {
    width: 83px;
    height: 17px;

    font-family: 'Russo One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #FFFFFF;


    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.tape {
    background-image: url("./assets/tape1.png");
    height: 150px;
    background-size: cover;
    width: 100%;
}

.tape2 {
    background-image: url("./assets/tape2.png");
    height: 150px;
    background-size: cover;
    /*margin: -30px 0;*/
    margin-bottom: 10px;

}




.event-game-amulet-photo-image.mask {
    mix-blend-mode: multiply;
    background: black;
}



@media (max-width: 768px) {
    .background-container {
        background-position: top;
    }
}

.card {
    width: 140px;
    height: 200px;
    border-radius: 20px;
    border: 2px solid #FFDD36;
    padding: 3px;
    position: relative;
    color: #fff;
    font-family: Russo One, sans-serif;
}

.selected-card {
    border: 1px solid #FFFFFF;
    box-shadow: 0 0 4px #FFFFFF, 0 0 10px #3EC84B, inset 0 0 4px #FFFFFF, inset 0 0 10px #3EC84B;
}

.card-bg {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background: linear-gradient(0deg, #172730, #31474e);
    position: relative;
    overflow: hidden;
}

.card-bg-image {
    overflow: hidden;
    background-size: contain;
    background: url('./assets/cards/cardTapes.png') no-repeat;
    border-radius: 25px;
    width: inherit;
    height: 100%;
    position: absolute;
    z-index: 1;
    background-size: contain;
}

.card-image {
    /*overflow: hidden;*/
    /*border-radius: 25px;*/
    width: inherit;
    height: inherit;
    position: absolute;
    z-index: 2;
}

.card-info {
    position: absolute;
    z-index: 20000;
    top: -10px;
    left: -12px;
    width: 160px;
    display: flex;
    justify-content: space-between;
    font-family: FROH, sans-serif;
    font-size: 16px;
}

.card-info-price {
    padding: 0 10px;
    background: #00C2FF;
    border-radius: 50px;
    display: flex;
    /*align-items: center;*/
    /*gap: 4px;*/
    /*font-size: 14px;*/
}

.coin-icon {
    object-fit: contain;
    display: block;
}

.card-info-probability {
    padding: 0 10px;
    background: #BDFF00;
    color: #000;
    border-radius: 50px;
}

.don {
    background: url('./assets/cards/icons/don.png') no-repeat;
    background-size: cover;
}

.mafia {
    background: url('./assets/cards/icons/mafiaLilith.png') no-repeat;
    background-size: cover;
    top: 4px;
    width: 131px;
    height: 185px;
    left: -1px;
}

.civilian {
    background: url('./assets/cards/icons/civilianBobby.png') no-repeat;
    background-size: cover;
}

.civilianMarty {
    background: url('./assets/cards/icons/civilianMarty.png') no-repeat;
    background-size: cover;
    scale: 1.3;
    top: 37px;
    left: -17px;
}

.civilianHilda {
    background: url('./assets/cards/icons/civilianHilda.png') no-repeat;
    background-size: cover;
}

.civilianTed {
    background: url('./assets/cards/icons/civilianTed.png') no-repeat;
    background-size: cover;
}

.civilianBobby {
    background: url('./assets/cards/icons/civilianBobby.png') no-repeat;
    background-size: cover;
}

.civilianCole {
    background: url('./assets/cards/icons/civilianCole.png') no-repeat;
    background-size: cover;
    scale: 1.5;
    top: 33px;
    left: -6px;
}

.civilianNaruto {
    background: url('./assets/cards/icons/civilianHaruto.png') no-repeat;
    background-size: cover;
    scale: 1.3;
    top: 27px;
    left: -7px;
}

.civilianLauren {
    background: url('./assets/cards/icons/civilianLauren.png') no-repeat;
    background-size: cover;
    scale: 1.5;
    top: 18px;
    left: -7px;
}


.mafiaSonnie {
    background: url('./assets/cards/icons/mafiaSonnie.png') no-repeat;
    background-size: cover;
}

.mafiaArchie {
    background: url('./assets/cards/icons/mafiaArchie.png') no-repeat;
    background-size: cover;
    scale: 1.6;
    position: absolute;
    top: 12px;
    left: -12px;
}

.mafiaLilith {
    background: url('./assets/cards/icons/mafiaLilith.png') no-repeat;
    background-size: cover;
}

.mafiaMadison {
    background: url('./assets/cards/icons/mafiaMadison.png') no-repeat;
    background-size: cover;
    scale: 1.5;
    top: 10px;
    left: -2px;
}

.mafiaStephanie {
    background: url('./assets/cards/icons/mafiaStephanie.png') no-repeat;
    background-size: contain;
    top: 152px;
    scale: 2.5;
}

.mafiaVincent {
    background: url('./assets/cards/icons/mafiaVincent.png') no-repeat;
    background-size: cover;
    scale: 1.5;
    top: 15px;
    left: -12px;
}



.killer {
    background: url('./assets/cards/icons/killer.png') no-repeat;
    background-size: cover;
    width: 135px;
    height: 185px;
    top: 5px;
    left: -3px;
}

.switchman {
    background: url('./assets/cards/icons/switchman.png') no-repeat;
    background-size: cover;
    top: 7px;
    width: 131px;
    height: 184px;
    left: 1px;
}


.bomb {
    background: url('./assets/cards/icons/bomb.png') no-repeat;
    background-size: cover;
}

.jailor, .jailer {
    background: url('./assets/cards/icons/jailor.png') no-repeat;
    background-size: cover;
}


.slut, .ledi {
    background: url('./assets/cards/icons/slut.png') no-repeat;
    background-size: cover;
}

.sheriff {
    background: url('./assets/cards/icons/sheriff.png') no-repeat;
    background-size: cover;
    width: 230px;
    height: 206px;
    top: -10px;
    left: -47px;
}

.doctor {
    background: url('./assets/cards/icons/doctor.png') no-repeat;
    background-size: cover;
    width: 181px;
    left: -30px;
    top: -10px;
    height: 205px;
}

.stump {
    background: url('./assets/cards/icons/stump.png') no-repeat;
    background-size: cover;
    width: 192px;
    left: -35px;
    top: -6px;
    height: 232px;
}

.werewolf {
    background: url('./assets/cards/icons/werewolf.png') no-repeat;
    background-size: cover;
    width: 206px;
    left: -37px;
    top: -8px;
    height: 226px;
}




.card-role {
    position: absolute;
    z-index: 30;
    width: 100%;
    height: auto;
    bottom: 0;
    background: #0F0F0F75;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 4px;
    word-break: break-word;
    white-space: normal;
}

.card-disable {
    position: absolute;
    background: rgb(117, 117, 117, 0.8);
    width: 137px;
    height: 196px;
    top: 0;
    left: 0;
    z-index: 1000;
    border-radius: 18px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.header-text {
    width: 100%;
    height: 22px;

    font-family: 'Russo One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
}

.header-desc {
    width: 90%;
    font-family: 'Russo One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
}

.header-description {
    width: 90%;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #848484;
    justify-content: center;
    margin: 30px auto;
    gap: 10px;
}

.header-description-info {
    display: flex;
}

.context-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100px;
    /*height: 54px;*/
    padding: 8px;
    gap: 10px;
    border-radius: 12px;
    background:
            linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), /* Полупрозрачный черный */
            linear-gradient(180deg, #4A4641 10.75%, #4B8F89 182.8%); /* Основной градиент */
    backdrop-filter: blur(10px);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: #FFFFFF;
    z-index: 50000;
}

.context-menu-item {
    background-color: transparent;
    border: none;
    color: #fff;
    font-size: 12px;
    font-family: 'OpenSans', sans-serif;
    padding: 0;
}

/* Общие стили для контейнера */
.info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
    /*padding: 20px;*/
    color: white;
    font-family: 'Russo One', sans-serif;
}



/* Стили для блока с элементом */
.element-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
}

.element-info {
    position: relative;
    width: 250px;
    height: 250px;
    gap: 10px;
    border-radius: 24px;
    border: 2px solid #FFC300;
    box-shadow: 0 0 16px 0 #FFC30050;
    padding: 8px;
}

.element-box {
    width: 100%;
    height: 100%;
    padding: 10px;
    gap: 10px;
    border-radius: 16px;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.element-text {
    color: #bdc3c7;
}

/* Текстовое описание */
.info-description {
    text-align: center;
    padding: 20px;
    color: #ecf0f1;
}

.info-description p {
    margin: 0;
    line-height: 1.5;
}

.popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgb(0 0 0 / 80%);
    width: 300px;
    /*height: 500px;*/
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

.popup.role {
    background-color: rgb(0 0 0 / 90%);
}

.popup-text {
    font-family: 'Russo One', sans-serif;
    font-size: 16px;
    width: 90%;
    margin-top: 50px;
}

.popup-button {
    border-radius: 100px;
    background: #2FFBA560;
    border: 1px solid #FFFFFF;
    box-shadow: 0 0 4px #FFFFFF, 0 0 12px #3EC84B, inset 0 0 4px #FFFFFF, inset 0 0 12px #3EC84B;
    font-family: 'Russo One', sans-serif;
    font-size: 16px;
    color: #FFFFFF;
    width: 50px;
    margin-bottom: 50px;
}

/*.desc-popup-text {*/
/*    width: 90%;*/
/*}*/

/*.desc-popup {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    background-color: rgb(0 0 0 / 80%);*/
/*    width: 300px;*/
/*    !*height: 500px;*!*/
/*    border-radius: 20px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    flex-direction: column;*/
/*    gap: 40px;*/
/*}*/

/*.popup-text {*/
/*    font-family: 'Russo One', sans-serif;*/
/*    font-size: 16px;*/
/*    width: 90%;*/
/*    margin-top: 50px;*/
/*}*/

.container-blur {
    filter: blur(3px);
    pointer-events: none;
}

.no-scroll {
    overflow: hidden;
    height: 100vh; /* предотвращает прокрутку на мобильных устройствах */
}

.context-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 80%; /* Ширина 80% страницы */
    left: 50%; /* Горизонтальное центрирование */
    transform: translateX(-50%);
    padding: 8px;
    gap: 10px;
    border-radius: 12px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), /* Полупрозрачный черный */ linear-gradient(180deg, #4A4641 10.75%, #4B8F89 182.8%);
    backdrop-filter: blur(10px);
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: #FFFFFF;
    z-index: 50000;
}

